body {
  margin: 64px 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --base-unit: 8px;
  --spacing-unit: calc(var(--base-unit) * 2);
  --font-primary: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
}
